<template>
  <base-view-layout  v-if="myUsers">
    <template #breadcrumb>
      <li class="breadcrumb__nav-item">{{$t('teacherlist.breadcrumb')}}</li>
    </template>

    <template #title>{{$t('teacherlist.breadcrumb')}}</template>
    <template #subtitle>{{$t('teacherlist.subtitle')}} <span class="meaning">+MEANING</span></template>

    <section class="main-section" v-if="users">
      <div class="grid grid-3">
        <div v-for="user in users" :key="user.id" class="card">
          <div class="card__top">
            <div class="card__icon">{{$utils.extractInitials(user)}}</div>
            <div class="card__titles">
              <h3>{{user.first_name}} {{user.last_name}}</h3>
            </div>
          </div>
          <div class="card__middle">
            <div class="card__info">
              <div class="card__info-item">
                <img class="card__item-icon" src="@/assets/images/icons/ico-email--yellow.svg">
                <p class="card__item-data">{{user.email}}</p> 
              </div>
            </div>
          </div>
          <div class="card__actions">
            <div class="dropdown">
              <button class="btn btn--primary btn--block">{{$t('teacherlist.btnoptions')}}</button>
              <div class="dropdown__options">
                <router-link :to="{ name: 'TeachersShow', params: { userId: user.id } }">{{$t('teacherlist.linkedit')}}</router-link>
                <a class="danger" v-if="user.id !== $store.state.user.id" href="" @click.prevent="deleteUser(user.id)">{{$t('teacherlist.delete')}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="main-section" v-if="deletedUsers">
      <h3 class="main-section__title">{{$t('teacherlist.disablesprofesor')}}</h3>
      <div class="list">
        <div v-for="user in deletedUsers" :key="user.id" class="card">
          <div class="card__top">
              <div class="card__icon">{{$utils.extractInitials(user)}}</div>
              <div class="card__titles">
                <h3>{{user.first_name}} {{user.last_name}}</h3>
              </div>
            </div>
            <div class="card__middle">
              <div class="card__info">
                <div class="card__info-item">
                  <img class="card__item-icon" src="@/assets/images/icons/ico-email--yellow.svg">
                  <p class="card__item-data">{{user.email}}</p> 
                </div>
              </div>
            </div>
            <div class="card__actions">
            <div class="dropdown">
              <button class="btn btn--primary btn--outline btn--xs">{{$t('teacherlist.btnoptions')}}</button>
              <div class="dropdown__options">
                <router-link :to="{ name: 'TeachersShow', params: { userId: user.id } }">{{$t('teacherlist.linkedit')}}</router-link>
                <a href="" @click.prevent="enableUser(user.id)">{{$t('teacherlist.rehabilitarprofesor')}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </base-view-layout>
</template>

<script>
import BaseViewLayout from '@/components/BaseViewLayout'

export default {
  name: 'TeachersList',
  data () {
    return {
      myUsers: null,
      showConfirmDeleteUserModal: false
    }
  },
  methods: {
    deleteUser (id) {
      if (confirm(this.$t('teacherlist.eliminaralert'))) {
        this.$axios.delete(`users/${id}`).then(() => {
          this.myUsers.find(user => user.id === id).deleted = true
          this.showConfirmDeleteUserModal = false
        })
      } else {
        this.showConfirmDeleteUserModal = false
      }
    },
    enableUser (id) {
      if (confirm(this.$t('teacherlist.rehabilitaralert'))) {
        this.$axios.post(`users/${id}/enable`).then(() => {
          this.myUsers.find(user => user.id === id).deleted = false
          this.showConfirmEnableUserModal = false
        })
      } else {
        this.showConfirmEnableUserModal = false
      }
    }
  },
  computed: {
    users () {
      let users = this.myUsers && this.myUsers.filter(user => !user.deleted)
      if (users && !users.length) users = null
      return users
    },
    deletedUsers () {
      let users = this.myUsers && this.myUsers.filter(user => user.deleted)
      if (users && !users.length) users = null
      return users
    }
  },
  components: { BaseViewLayout },
  async created () {
    let res = await this.$axios.get('users/teachers')
    this.myUsers = res.data.users
  }
}
</script>
